const actions = {
  SET_STATE: "hardware/SET_STATE",
  CREATE: "hardware/CREATE",
  LIST: "hardware/LIST",
  UPDATE: "hardware/UPDATE",
  DETAIL: "hardware/DETAIL",
  DELETE: "hardware/DELETE",
};

export default actions;
