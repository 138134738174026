import { useState, useEffect, Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "layouts/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { verifyEmail } from "utils/helper";
import { AuthProvider } from "auth-context/auth.context";
import { CircularProgress } from "@mui/material";
import { ProtectedRoute } from "./ProtectedRoute";

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname, search } = useLocation();
  const result = new URLSearchParams(search).get("result");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return <ProtectedRoute path={route.route} component={route.component} key={route.key} />;
        }
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {!pathname.startsWith("/accounts") && pathname !== "/404" ? (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName="EGATEWAY | ADMINTOOL"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      ) : null}
      <Suspense
        fallback={
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <CircularProgress color="info" size="1rem" disableShrink />
            <span> &nbsp; Loading...</span>
          </div>
        }
      >
        <AuthProvider>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            {getRoutes(routes)}
            {verifyEmail(result)}
            <Redirect from="*" to="/404" />
          </Switch>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
