import { all } from "redux-saga/effects";
/* eslint-disable import/no-cycle */
import auth from "./auth/sagas";
import hardware from "./hardware/sagas";
import firmware from "./firmware/sagas";
import version from "./version/sagas";
import deployment from "./environment/sagas";
import environmentVersions from "./environmentVersions/sagas";
import user from "./user/sagas";
import device from "./device/sagas";
import group from "./group/sagas";

export default function* rootSaga() {
  yield all([
    auth(),
    hardware(),
    firmware(),
    version(),
    deployment(),
    environmentVersions(),
    user(),
    device(),
    group(),
  ]);
}
