import axios from "axios";
import config from "config/config";
import store from "store";
import { get } from "lodash";
import { history } from "redux/store";
import { toastr } from "react-redux-toastr";
import ERROR from "constant/error";
import { isRefreshTokenExpired, isAccessTokenExpired, auth } from "utils/helper";
import { refreshToken } from "services/apis/auth";

const baseURL = config.API_ENDPOINT;
const apiClient = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    "x-powered-by": "ubisen",
    "Access-Control-Expose-Headers": "Content-Disposition",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
apiClient.interceptors.request.use(
  (request) => {
    const isRefreshTokenExp = isRefreshTokenExpired();
    const isAccessTokenExp = isAccessTokenExpired();
    if (isRefreshTokenExp && !auth.includes(request.url)) {
      store.remove("accessToken");
      store.remove("refreshToken");
      history.push("/accounts/sign-in");
      toastr.warning("session Expired", "Please login again");
    }
    const accessToken = store.get("accessToken");
    if (accessToken && !isAccessTokenExp) {
      request.headers = {
        authorization: `bearer ${accessToken.token}`,
      };
    }
    store.set("offline", false);

    return request;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Errors handling
    const { response } = error;
    const originalRequest = error.config;
    // eslint-disable-next-line no-console
    console.info("############INTERCEPTORS", response, error);
    if (!response) {
      store.set("offline", true);
      return;
    }

    const { data, status } = response;
    let message = get(data, "message");
    let name = get(data, "name");
    const errorLib = ERROR[name];
    if (errorLib) {
      message = errorLib.message;
      if (errorLib.name) {
        name = errorLib.name;
      }
    }

    const requestURL = get(response, ["request", "responseURL"], "");

    if (status === 404 && data.name === "FirmwareWithIdNotFoundException") {
      history.push("/404");
    }

    if (status === 401 && requestURL.indexOf(`${baseURL}/api/accounts/refresh-access-token`) > -1) {
      store.remove("accessToken");
      store.remove("refreshToken");
      history.push("/accounts/sign-in");
    }

    if (
      status === 400 &&
      requestURL.indexOf(`${baseURL}/api/accounts/send-reset-password-email`) > -1
    ) {
      history.push("/accounts/send-reset-password-email-failed");
    }

    // eslint-disable-next-line no-underscore-dangle
    if (status === 401 && !originalRequest._retry) {
      const token = store.get("refreshToken");
      const isAccessTokenExp = isAccessTokenExpired();

      if (isAccessTokenExp && token) {
        const res = await refreshToken(token.token);
        axios.defaults.headers.common = {
          authorization: `bearer ${res.token}`,
        };
        // eslint-disable-next-line consistent-return
        return apiClient(originalRequest);
      }
    }

    toastr.warning(name, message);
    // if (status >= 500) {
    //   throw error;
    // }
  }
);

export default apiClient;
