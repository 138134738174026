import apiClient from "services/axios";

const API = {
  list: "/api/groups",
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};
