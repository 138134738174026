const actions = {
  SET_STATE: "environment_versions/SET_STATE",
  SET_FULL_LIST_STATE: "environment_versions/SET_FULL_LIST_STATE",
  FULL_LIST: "environment_versions/FULL_LIST",
  CREATE: "environment_versions/CREATE",
  LIST: "environment_versions/LIST",
  DELETE: "environment_versions/DELETE",
};

export default actions;
