import apiClient from "services/axios";

const API = {
  create: "/api/firmwares",
  list: "/api/firmwares",
  update: "/api/firmwares/{0}",
  detail: "/api/firmwares/{0}",
  remove: "/api/firmwares/{0}",
};

export const create = async (body) => {
  const response = await apiClient.post(API.create, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id) => {
  const response = await apiClient.get(API.detail.replace("{0}", id));
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (id, body) => {
  const response = await apiClient.patch(API.update.replace("{0}", id), body);
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (id) => {
  const response = await apiClient.delete(API.remove.replace("{0}", id));
  if (response) {
    return response.data;
  }
  return false;
};
