import apiClient from "services/axios";

const API = {
  resendConfirmEmail: "/api/accounts/resend-confirm-email",
  sendResetPasswordEmail: "/api/accounts/send-reset-password-email",
};

export const resendConfirmEmail = async (email) => {
  const response = await apiClient.post(API.resendConfirmEmail, { email });
  if (response) {
    return response.data;
  }
  return false;
};

export const sendResetPasswordEmail = async (email, recaptcha) => {
  const response = await apiClient.post(
    API.sendResetPasswordEmail,
    { email },
    { headers: { recaptcha } }
  );
  if (response) {
    return response.data;
  }
  return false;
};
