/* eslint-disable import/no-cycle */
import { all, takeEvery, call, put } from "redux-saga/effects";
import { create, list, detail, update, remove } from "services/apis/environment";
import { toastr } from "react-redux-toastr";
import actions from "./actions";

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error,
    },
  });
}

export function* CREATE({ payload }) {
  const { filter, body } = payload;
  yield loading(true);

  const response = yield call(create, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        detail: response,
      },
    });
    yield put({ type: actions.LIST, payload: filter });
    toastr.success("Create succeeded", "Successfully created environment!");
  }
  if (!response) {
    yield loading(false, true);
  }
}

export function* LIST({ payload }) {
  yield loading(true);

  const response = yield call(list, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response.data,
        meta: response.meta,
      },
    });
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* DETAIL({ payload }) {
  const { id } = payload;
  yield loading(true);

  const response = yield call(detail, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        detail: response,
      },
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE({ payload }) {
  const { id, body, filter } = payload;
  yield loading(true);

  const response = yield call(update, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        detail: response,
      },
    });
    yield put({ type: actions.LIST, payload: filter });
    toastr.success("Updated succeeded", "Successfully updated environment!");
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DELETE({ payload }) {
  const { id, filter, total } = payload;
  const { page, limit } = filter;
  let newPage = page;

  if (total % limit <= 1 && page !== 1) {
    newPage -= 1;
  }

  yield loading(true);
  const response = yield call(remove, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        detail: response,
      },
    });
    yield put({ type: actions.LIST, payload: { ...filter, page: newPage } });
    toastr.success("Delete succeeded", "Successfully deleted environment!");
  }

  if (!response) {
    yield loading(false, true);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ]);
}
