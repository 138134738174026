const actions = {
  SET_STATE: "firmware/SET_STATE",
  CREATE: "firmware/CREATE",
  LIST: "firmware/LIST",
  DETAIL: "firmware/DETAIL",
  UPDATE: "firmware/UPDATE",
  DELETE: "firmware/DELETE",
};

export default actions;
