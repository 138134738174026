/* eslint-disable import/no-cycle */
import { all, takeEvery, call, put } from "redux-saga/effects";
import { registerAdmin, signUp, login, logout, resetPassword } from "services/apis/auth";
import { resendConfirmEmail, sendResetPasswordEmail } from "services/apis/mail";
import { history } from "redux/store";
import { toastr } from "react-redux-toastr";
import store from "store";
import { checkIsAdmin } from "utils/helper";
import actions from "./actions";

function* loading(isLoading = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
    },
  });
}

export function* REGISTER({ payload }) {
  const { name, email, password } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const success = yield call(registerAdmin, name, email, password);
  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    yield history.push("/accounts/sign-in");
    toastr.success("Register succeeded", "You have successfully registered!");
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* SIGNUP({ payload }) {
  const { name, email, password, recaptcha } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const success = yield call(signUp, name, email, password, recaptcha);
  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    yield history.push("/accounts/confirm-email");
    toastr.success("Signup succeeded", "You have successfully signup!");
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(login, email, password);

  if (response) {
    const { user } = response;
    const isAdmin = checkIsAdmin(user.role);
    if (isAdmin) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          data: response,
          loading: false,
          isLogin: true,
        },
      });
      yield history.push("/");
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
      toastr.warning(
        "Access denied",
        "You don't have permission to login to web please go to mobile application!"
      );
    }
  }

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT({ payload }) {
  const { accessToken } = payload;
  yield call(logout, accessToken);
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLogin: false,
      loading: false,
      data: {},
    },
  });

  yield history.push("/accounts/sign-in");

  store.clearAll();
}

export function* RESEND_CONFIRM_EMAIL({ payload }) {
  const { email } = payload;
  yield loading(true);

  const response = yield call(resendConfirmEmail, email);

  if (response) {
    yield loading(false);
    yield history.push("/accounts/confirm-email");
    toastr.success("Resend email succeeded", "Send email successfully!");
  }

  if (!response) {
    yield loading(false);
  }
}

export function* SEND_RESET_PASSWORD_EMAIL({ payload }) {
  const { email, recaptcha } = payload;
  yield loading(true);

  const response = yield call(sendResetPasswordEmail, email, recaptcha);
  if (response) {
    yield loading(false);
    yield history.push("/accounts/send-reset-password-email-success");
    toastr.success("Send email succeeded", "Send reset password email successfully!");
  }

  if (!response) {
    yield loading(false);
  }
}

export function* RESET_PASSWORD({ payload }) {
  const { body, token } = payload;
  yield loading(true);

  const response = yield call(resetPassword, body, token);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response,
      },
    });
    history.push("/accounts/reset-password-success");
    toastr.success("Reset password succeeded", "Your account password reset successfully!");
  }

  if (!response) {
    yield loading(false);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.SIGNUP, SIGNUP),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.RESEND_CONFIRM_EMAIL, RESEND_CONFIRM_EMAIL),
    takeEvery(actions.SEND_RESET_PASSWORD_EMAIL, SEND_RESET_PASSWORD_EMAIL),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
  ]);
}
