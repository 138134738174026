const actions = {
  SET_STATE: "device/SET_STATE",
  GENERATE: "device/GENERATE",
  LIST: "device/LIST",
  DETAIL: "device/DETAIL",
  UPDATE_ENVIRONMENT: "device/UPDATE_ENVIRONMENT",
  UPDATE_MANUFACTURE_STATUS: "device/UPDATE_MANUFACTURE_STATUS",
};

export default actions;
