import { lazy } from "react";
import { Route } from "react-router-dom";
import globalStore from "store";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "constant";

export const auth = [
  "/api/accounts/register",
  "/api/accounts/admin",
  "/api/accounts/confirm",
  "/api/accounts/login",
  "/api/accounts/resend-confirm-email",
  "/api/accounts/refresh-access-token",
  "/api/accounts/reset-password",
];

export const verifyEmail = (result) => {
  if (result && result === "succeed") {
    return (
      <Route
        exact
        path="/accounts/verified"
        component={lazy(() => import("pages/mail/confirm-email-success"))}
      />
    );
  }

  if (result && result === "failed") {
    return (
      <Route
        exact
        path="/accounts/verified"
        component={lazy(() => import("pages/mail/confirm-email-failed"))}
      />
    );
  }
  return null;
};

export const checkIsAdmin = (role) => role === "admin";
export const isAccessTokenExpired = () => {
  const accessToken = globalStore.get("accessToken");
  if (accessToken && Date.now() > new Date(accessToken.expiredAt).getTime() - 3000) {
    return true;
  }
  return false;
};

export const isRefreshTokenExpired = () => {
  const refreshToken = globalStore.get("refreshToken");
  if (refreshToken && Date.now() > new Date(refreshToken.expiredAt).getTime() - 3000) {
    return true;
  }
  return false;
};

export const countPaginationRange = (meta, entity) => `${
  meta.totalItems > 0 ? meta.itemsPerPage * meta.currentPage - meta.itemsPerPage + 1 : 0
} -
${
  meta.itemsPerPage * meta.currentPage > meta.totalItems
    ? meta.totalItems
    : meta.itemsPerPage * meta.currentPage
}
OF ${meta.totalItems} ${entity} `;

export const reCaptcha = async (action) =>
  await window.grecaptcha.enterprise.execute(GOOGLE_RECAPTCHA_SITE_KEY, {
    action,
  });
