module.exports = {
  UserWithEmailNotVerifiedException: {
    name: "EmailNotVerified",
    message: "Email address is Unverified! please first go to your email to verify it.",
  },
  AdminAlreadyInstalledException: {
    name: "AlreadyHaveAdmin",
    message: "You can't register as admin because system already have 1 admin",
  },
  ConfirmEmailAlreadyVerifiedException: {
    name: "EmailAlreadyVerified",
    message: "Your email address already verified",
  },
  LoginFailedException: {
    name: "LoginFailed",
    message: "Your email or password is incorrect",
  },
  EmailAlreadyExistException: {
    name: "EmailAlreadyExisted",
    message:
      "Account with this email already exists, Please login using this email or reset the password",
  },
  ResetPasswordEmailWithUserNotFoundException: {
    name: "UserNotFound",
    message: "There is no user registered with this email.",
  },
  BadGatewayException: {
    name: "InvalidToken",
    message: "JWT reset password token is invalid or expired",
  },
  FirmwareWithIdNotFoundException: {
    name: "FirmwareNotFound",
    message: "There is no firmware found for current FirmwareId",
  },
};
