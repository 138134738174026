const actions = {
  SET_STATE: "auth/SET_STATE",
  LOGIN: "auth/LOGIN",
  REGISTER: "auth/REGISTER",
  SIGNUP: "auth/SIGNUP",
  LOGOUT: "auth/LOGOUT",
  RESEND_CONFIRM_EMAIL: "auth/RESEND_CONFIRM_EMAIL",
  SEND_RESET_PASSWORD_EMAIL: "auth/SEND_RESET_PASSWORD_EMAIL",
  RESET_PASSWORD: "auth/RESET_PASSWORD",
};

export default actions;
