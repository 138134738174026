import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import { history } from "redux/store";
// import SweetAlert from "react-bootstrap-sweetalert";

// eslint-disable-next-line import/prefer-default-export
export const ProtectedRoute = ({ ...rest }) => {
  const { user } = useAuth();
  if (!user || !user.token || user.token === "") {
    history.push("/accounts/sign-in");
  }

  return <Route {...rest} />;
};
