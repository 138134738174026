import apiClient from "services/axios";

const API = {
  generate: "/api/devices/generate",
  list: "/api/devices",
  detail: "/api/devices/{0}",
  updateEnvironment: "/api/devices/{0}/environment",
  updateManufactureStatus: "/api/devices/{0}/manufacture-status",
};

export const generate = async (body) => {
  const response = await apiClient.post(API.generate, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id) => {
  const response = await apiClient.get(API.detail.replace("{0}", id));
  if (response) {
    return response.data;
  }
  return false;
};

export const updateEnvironment = async (id, body) => {
  const response = await apiClient.patch(API.updateEnvironment.replace("{0}", id), body);
  if (response) {
    return response.data;
  }
  return false;
};

export const updateManufactureStatus = async (id, body) => {
  const response = await apiClient.patch(API.updateManufactureStatus.replace("{0}", id), body);
  if (response) {
    return response.data;
  }
  return false;
};
