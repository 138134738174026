const actions = {
  SET_STATE: "environment/SET_STATE",
  CREATE: "environment/CREATE",
  LIST: "environment/LIST",
  DETAIL: "environment/DETAIL",
  UPDATE: "environment/UPDATE",
  DELETE: "environment/DELETE",
};

export default actions;
