import { all, takeEvery, call, put } from "redux-saga/effects";
import {
  list,
  generate,
  detail,
  updateEnvironment,
  updateManufactureStatus,
} from "services/apis/device";
import { toastr } from "react-redux-toastr";
import actions from "./actions";

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error,
    },
  });
}

export function* GENERATE({ payload }) {
  const { body, filter } = payload;
  const { number } = body;
  yield loading(true);

  const response = yield call(generate, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
      },
    });
    yield put({ type: actions.LIST, payload: filter });
    toastr.success("Generate succeeded", `Successfully generated ${number} devices!`);
  }
  if (!response) {
    yield loading(false, true);
  }
}

export function* LIST({ payload }) {
  yield loading(true);

  const response = yield call(list, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response.data,
        meta: response.meta,
      },
    });
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* DETAIL({ payload }) {
  const { id } = payload;
  yield loading(true);

  const response = yield call(detail, id);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
      },
    });
  }
  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE_ENVIRONMENT({ payload }) {
  const { id, body, filter } = payload;
  yield loading(true);

  const response = yield call(updateEnvironment, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
      },
    });
    yield put({ type: actions.LIST, payload: filter });
    toastr.success("Updated succeeded", "Successfully updated device environment!");
  }
  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE_MANUFACTURE_STATUS({ payload }) {
  const { id, body, filter } = payload;
  yield loading(true);

  const response = yield call(updateManufactureStatus, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
      },
    });
    yield put({ type: actions.LIST, payload: filter });
    toastr.success("Updated succeeded", "Successfully updated device manufacture status!");
  }
  if (!response) {
    yield loading(false, true);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.GENERATE, GENERATE),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.UPDATE_ENVIRONMENT, UPDATE_ENVIRONMENT),
    takeEvery(actions.UPDATE_MANUFACTURE_STATUS, UPDATE_MANUFACTURE_STATUS),
  ]);
}
