import React from "react";
import PropTypes from "prop-types";
import globalStore from "store";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const token = globalStore.get("accessToken");
  let user = { token: token ? token.token : token };
  user = typeof user === "string" ? JSON.parse(user) : user;
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
