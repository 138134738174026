import apiClient from "services/axios";

const API = {
  create: "/api/environment-versions",
  list: "/api/environment-versions",
  delete: "/api/environment-versions/{0}",
};

export const create = async (body) => {
  const response = await apiClient.post(API.create, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (id) => {
  const response = await apiClient.delete(API.delete.replace("{0}", id));
  if (response) {
    return response.data;
  }
  return false;
};
