import Icon from "@mui/material/Icon";
import { lazy } from "react";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: lazy(() => import("pages/dashboard")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    key: "devices",
    route: "/devices/:id",
    component: lazy(() => import("pages/device/detail")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Device Management",
    key: "devices",
    icon: <Icon fontSize="small">devices</Icon>,
    route: "/devices",
    component: lazy(() => import("pages/device")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    key: "firmwares",
    route: "/firmwares/:id",
    component: lazy(() => import("pages/firmware/detail")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Firmware Management",
    key: "firmwares",
    icon: <Icon fontSize="small">memory</Icon>,
    route: "/firmwares",
    component: lazy(() => import("pages/firmware")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Hardware Management",
    key: "hardwares",
    icon: <Icon fontSize="small">developer_board</Icon>,
    route: "/hardwares",
    component: lazy(() => import("pages/hardware")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: lazy(() => import("pages/user")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: lazy(() => import("pages/profile")),
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    key: "sign-in",
    route: "/accounts/sign-in",
    component: lazy(() => import("pages/authentication/sign-in")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "register-admin",
    route: "/accounts/register-admin",
    component: lazy(() => import("pages/authentication/register-admin")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "sign-up",
    route: "/accounts/sign-up",
    component: lazy(() => import("pages/authentication/sign-up")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "confirm-email",
    route: "/accounts/confirm-email",
    component: lazy(() => import("pages/mail/confirm-email")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "resend-confirm-email",
    route: "/accounts/resend-confirm-email",
    component: lazy(() => import("pages/mail/resend-confirm-email")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "reset-password-success",
    route: "/accounts/reset-password-success",
    component: lazy(() => import("pages/authentication/reset-password/reset-password-success")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "reset-password",
    route: "/accounts/reset-password",
    component: lazy(() => import("pages/authentication/reset-password")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "send-reset-password-email",
    route: "/accounts/send-reset-password-email",
    component: lazy(() => import("pages/mail/reset-password/send-reset-password-email")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "send-reset-password-email-success",
    route: "/accounts/send-reset-password-email-success",
    component: lazy(() => import("pages/mail/reset-password/send-reset-password-email-success")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "send-reset-password-email-failed",
    route: "/accounts/send-reset-password-email-failed",
    component: lazy(() => import("pages/mail/reset-password/send-reset-password-email-failed")),
    noCollapse: true,
  },
  {
    type: "none",
    key: "404",
    route: "/404",
    component: lazy(() => import("pages/errors/404")),
    noCollapse: true,
  },
];

export default routes;
