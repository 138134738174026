import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import auth from "./auth/reducer";
import hardware from "./hardware/reducer";
import firmware from "./firmware/reducer";
import version from "./version/reducer";
import environment from "./environment/reducer";
import environmentVersions from "./environmentVersions/reducer";
import user from "./user/reducer";
import device from "./device/reducer";
import group from "./group/reducer";

export default () =>
  combineReducers({
    auth,
    toastr: toastrReducer,
    hardware,
    firmware,
    version,
    environment,
    environmentVersions,
    user,
    device,
    group,
  });
