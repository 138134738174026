import apiClient from "services/axios";

const API = {
  create: "/api/versions",
  list: "/api/versions",
  detail: "/api/versions/{0}",
};

export const create = async (body) => {
  const formData = new FormData();
  formData.append("major", body.major);
  formData.append("minor", body.minor);
  formData.append("patch", body.patch);
  formData.append("description", body.description);
  formData.append("partitionFile0", body.partitionFile0);
  formData.append("partitionFile1", body.partitionFile1);
  formData.append("chunkSize", body.chunkSize);
  formData.append("firmwareId", body.firmwareId);
  const response = await apiClient.post(API.create, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response) {
    return response.data;
  }
  return false;
};

export const list = async (data) => {
  const response = await apiClient.get(API.list, { params: data });
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id) => {
  const response = await apiClient.get(API.detail.replace("{0}", id));
  if (response) {
    return response.data;
  }
  return false;
};
