import apiClient from "services/axios";
import store from "store";
import { checkIsAdmin } from "utils/helper";

const API = {
  register: "/api/accounts/admin",
  signUp: "/api/accounts/register",
  login: "/api/accounts/login",
  logout: "/api/accounts/logout",
  refreshToken: "/api/accounts/refresh-access-token",
  resetPassword: "/api/accounts/reset-password",
};

export const registerAdmin = async (name, email, password) =>
  apiClient
    .post(API.register, { name, email, password })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
        }
        return response.data;
      }
      return false;
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const signUp = async (name, email, password, recaptcha) =>
  apiClient
    .post(
      API.signUp,
      { name, email, password },
      {
        headers: { recaptcha },
      }
    )
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
        }
        return response.data;
      }
      return false;
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const login = async (email, password) =>
  apiClient
    .post(API.login, { email, password })
    .then((response) => {
      if (response) {
        const { id, role } = response.data.user;
        const { accessToken, refreshToken } = response.data;
        const isAdmin = checkIsAdmin(role);
        if (id && isAdmin) {
          store.set("accessToken", accessToken);
          store.set("refreshToken", refreshToken);
          store.set("auth.email", email);
          store.set("auth.id", id);
          store.set("auth.role", role);
        }
        return response.data;
      }
      return false;
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const logout = async (accessToken) =>
  apiClient
    .get(API.logout, {
      headers: { authorization: `bearer ${accessToken}` },
    })
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const refreshToken = (token) =>
  apiClient
    .get(API.refreshToken, {
      headers: { authorization: token },
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        store.set("accessToken", accessToken);
        return response.data;
      }
      return false;
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const resetPassword = async (body, token) => {
  const response = await apiClient.patch(API.resetPassword, body, {
    headers: { authorization: `bearer ${token}` },
  });
  if (response) {
    return response.data;
  }
  return false;
};
