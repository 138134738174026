import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
// import { routerMiddleware } from "connected-react-router";
import reducers from "redux/reducers";
import sagas from "./sagas";

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
// const routeMiddleware = routerMiddleware(history);
// const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(sagas);

export { store, history };
